import axios from 'axios';
import storageHelper from 'utils/helpers';
import { Mixpanel, mixpanelA } from '../../MixPanel';

const API_PROCESSOR_URL = window.SYSTEM_INFORMATION
  ? window.SYSTEM_INFORMATION.API_PROCESSOR_URL
  : '';

const API_FORMATTER_URL = window.SYSTEM_INFORMATION
  ? window.SYSTEM_INFORMATION.API_FORMATTER_URL
  : '';

let token = '';
try {
  token = localStorage.token;
} catch (err) {
  // eslint-disable-next-line no-console
  console.log(err);
}
const _axiosConfig = {
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
  headers: {
    'Content-Type': 'application/json',
    // TODO localstorage not present
    Authorization: `Token ${token}`,
  },
};

const _axiosNoTokenConfig = {
  headers: { 'Cache-Control': 'no-cache', 'Access-Control-Allow-Origin': '*' },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
};

if (process.env.REACT_APP_LOCAL_BACKEND === 'https://test.dhaki.fr') {
  _axiosConfig.baseURL = 'https://test.dhaki.fr';
  _axiosConfig.headers['Access-Control-Allow-Origin'] = '*';

  _axiosNoTokenConfig.headers['Access-Control-Allow-Origin'] = '*';
  _axiosNoTokenConfig.baseURL = 'https://test.dhaki.fr';
}
const _axios = axios.create(_axiosConfig);

const _axiosNoToken = axios.create(_axiosNoTokenConfig);

axios.defaults.withCredentials = true;

export const BugRequestCreate = (data) => {
  return _axios.post('/api/bug-requests/', data);
};

export const getExercice = (id) => {
  return _axios.get(`/api/courses/exercice/${id}/`);
};

export const getAttempt = (id) => {
  return _axios.get(`/api/courses/attempt/${id}/`);
};

export const getUsers = ({ mode, exercice, schoolId,multiple_subscriptions = false }) => {
  let queryParam = '';
  if (mode) queryParam += `?mode=${mode}`;
  if (exercice) {
    const sep = queryParam ? '&' : '?';
    queryParam += `${sep}exercice=${exercice}`;
  }
  if (schoolId) {
    const sep = queryParam ? '&' : '?';
    queryParam += `${sep}school_id=${schoolId}`;
  }
  if (multiple_subscriptions) {
    const sep = queryParam ? '&' : '?';
    queryParam += `${sep}multiple_subscriptions=${multiple_subscriptions}`;
  }
  return _axios.get(`/api/users/${queryParam}`);
};

export const getUser = (user_id) => _axios.get(`/api/users/${user_id}/`);

export const getAttempts = ({ user_id, exercice_id }) =>
  _axios.get(`/api/courses/attempt/?exercice=${exercice_id}&user=${user_id}`);

// export const getQuestions = (id) => _axios.get(`/api/courses/questions/${id}/`);

export const getUnivers = (id) => _axios.get(`/api/organize/univers/${id}/`);

export const getUniverses = (params) => _axios.get(`/api/organize/univers/`, { params });

export const getUniversQuestions = (id) => _axios.get(`/api/organize/univers/${id}/questions/`);

export const getChapterQuestions = (id) => _axios.get(`/api/organize/chapter/${id}/questions/`);
export const updateChapterQuestions = (id, data) => _axios.patch(`/api/organize/chapter/${id}/questions/`, data);
export const getUniversByIdentifier = (identifier) =>
  _axios.get(`/api/organize/univers-identifier/${identifier}/`);



export const createSubscriptionTrackSchool = ({ users, universes, ...otherData }) => {
  const subscriptionTracks = users.map(user => ({
    user,
    universes: universes.map(u => u.id),
    ...otherData
  }));

  return new Promise((resolve, reject) =>
    _axios
      .post(`/api/organize/subscription-track-school/`, subscriptionTracks)
      .then((res) => resolve(res))
      .catch((err) => reject(err)),
  );
};
export const unlockTrackSchool = (trackId) =>
  _axios.patch(`/api/organize/track-school/${trackId}/unlock/`);

export const createSchool = (payload) => {
  return _axios.post(`/api/users/schools/`, payload);
};

export const submitQuiz = (chapter_id, attempted_questions) => {
  return new Promise((resolve, reject) =>
    _axios
      .post(`/api/courses/quiz/attempt/`, {
        chapter_id,
        attempted_questions,
      })
      .then((response) => {
        Mixpanel.track(`Tentative Quiz`, {
          is_succes: response.data.is_succes,
          chapter_id,
        });
        resolve(response);
      })
      .catch((err) => reject(err)),
  );
};

export const submitAttempt = (
  submissions: [],
  exercice,
  clientSideResult,
  user,
  promptProfileId,
  promptProfileIsCompilingId,
  elapsedTime,
) => {
  const files =
    exercice.files &&
    exercice.files.map((x) => {
      if (x.file_content) delete x.file_content;
      return x;
    });

  const schoolName = user && user.profile.school && user.profile.school.name;

  return new Promise((resolve, reject) =>
    _axios
      .post(`/api/courses/attempt/`, {
        prompt_profile_id: promptProfileId,
        prompt_profile_is_compiling_id: promptProfileIsCompilingId,
        exercice_id: exercice.id,
        submissions,
        files,
        client_side_result: clientSideResult,
        elapsed_time: elapsedTime,
      })
      .then((response) => {
        Mixpanel.track(`Tentative Exercice`, {
          is_succes: response.data.is_success,
          name: exercice.name,
          univers: exercice.univers ? exercice.univers.name : '',
          exercice_id: exercice.id,
          school_name: schoolName,
        });
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      }),
  );
};

export const getChapter = (chapterId) => _axios.get(`/api/organize/chapter/${chapterId}/`);

export const getCurrentUserInfo = () => _axios.get(`/api/users/current/`);
export const logoutUser = () => {
  return new Promise((resolve, reject) => {
    _axios
      .post(`/api/users/rest-auth/logout/`)
      .then((response) => {
        storageHelper.remove('token');
        storageHelper.remove('user_login');
        resolve(response);
        Mixpanel.reset();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createUserDemo = (subdomain) => {
  return new Promise((resolve, reject) => {
    return _axiosNoToken
      .post(`/api/users/demo/`, { subdomain })
      .then((response) => {
        Mixpanel.track('Demo Created');
        storageHelper.set('user_login', 'true');
        localStorage.setItem('token', response.data.key);
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const createUser = (payload) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const distinct_id = mixpanelA.get_distinct_id();
    formData.append('username', payload.email);
    formData.append('password1', payload.password);
    formData.append('password2', payload.repassword);
    formData.append('email', payload.email);
    formData.append('distinct_id', distinct_id);
    _axiosNoToken
      .post('/api/users/rest-auth/registration/', formData, {})
      .then((response) => {
        Mixpanel.track('Account Created');
        storageHelper.set('user_login', 'true');
        localStorage.setItem('token', response.data.key);
        resolve(response);
      })
      .catch((err) => {
        Mixpanel.track(`Error Account Creation`, { email: payload.email });
        reject(err);
      });
  });
};

export const DeleteAccount = (user_id) => {
  return new Promise((resolve, reject) => {
    _axios
      .delete(`/api/users/${user_id}/`, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const loginUser = (payload) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    // TODO replace that for working with test.dhaki.fr etc
    let subDomain = window.location.hostname
      .replace('www.', '')
      .replace('dhaki.fr', '')
      .replace('appforma.fr', '')
      .replace('.', '');
    if (subDomain === 'localhost' || subDomain === 'test') subDomain = '';
    const username = subDomain ? `${subDomain}_${payload.email}` : payload.email;
    formData.append('username', username);
    formData.append('password', payload.password);
    _axiosNoToken
      .post(`/api/users/login/`, formData, {})
      .then((response) => {
        Mixpanel.identify(payload.email);
        Mixpanel.track('Successful Login');
        storageHelper.set('user_login', 'true');
        localStorage.setItem('token', response.data.key);
        resolve(response);
      })
      .catch((err) => {
        Mixpanel.track(`Unsuccessful login`, { email: payload.email });
        reject(err);
      });
  });
};

export const ChangePasswordUser = (payload) => {
  return new Promise((resolve, reject) => {
    _axios
      .post(`/api/users/rest-auth/password/change/`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const resetPasswordUser = (payload) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('new_password', payload.new_password);
    formData.append('re_new_password', payload.re_new_password);
    formData.append('uid', payload.uid);
    formData.append('token', payload.token);
    Mixpanel.track('Reset password changed for user');

    _axiosNoToken
      .post(`/api/users/password/reset/confirm/`, formData, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          resolve(err.response);
        }
        reject(err);
      });
  });
};

export const emailResetPasswordUser = (payload) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Mixpanel.track('Reset password email send for user');
    formData.append('email', payload.email);
    _axiosNoToken
      .post(`/api/users/password/reset/`, formData, {})
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          resolve(err.response);
        }
        reject(err);
      });
  });
};

export const runCodeUser = (payload) => {
  return new Promise((resolve, reject) => {
    _axios
      .post('/api/courses/run', payload, {})
      .then((response) => resolve(response))
      .catch((err) => {
        reject(err);
      });
  });
};

export const createCheckoutSessionRoom = (roomId) => {
  return new Promise((resolve, reject) => {
    _axios
      .get(`/api/create-checkout-session/room/${roomId}/`)
      .then((response) => resolve(response))
      .catch((err) => {
        reject(err);
      });
  });
};

export const createCheckoutSession = (interval) => {
  return new Promise((resolve, reject) => {
    _axios
      .get(`/api/create-checkout-session/${interval}/`)
      .then((response) => resolve(response))
      .catch((err) => {
        reject(err);
      });
  });
};

export const createInvoiceSchool = ({ price_id }) => {
  return new Promise((resolve, reject) => {
    _axios
      .get(`/api/create-checkout-session-school/${price_id}`)
      .then((response) => resolve(response))
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleClickCredits = (stripe, price_id, setIsLoading) => {
  createInvoiceSchool({ price_id }).then((res) => {
    setIsLoading(false);
    window.open(res.data.hosted_invoice_url, '_blank');
  });
};

export const getLastPosts = (limit = 3) =>
  _axiosNoToken.get(
    `/blog/ghost/api/v3/content/posts/?key=${`ac07d1655d09b0fa8cce3ba8b9`}&limit=${limit}&include=tags,authors`,
  );

export const handleClickPay = (stripe, interval) => {
  Mixpanel.track(`subscription attempt`);
  createCheckoutSession(interval || 'month').then((res) => {
    stripe.redirectToCheckout({ sessionId: res.data.session_id });
  });
};

export const startSubscriptionTrack = (subscription_track_id) =>
  _axios.patch(`/api/users/subscriptions-school/${subscription_track_id}/`);

export const addUniversSubscriptionTrack = (subscriptionTrack, univers) =>
  _axios.patch(`/api/users/subscriptions-school/${subscriptionTrack.id}/univers/${univers.id}/`, {
    subscriptionTrack,
    univers,
  });

export const addCreditsSubscriptionTrack = (subscriptionTrack, numberCredits) =>
  _axios.patch(`/api/users/subscriptions-school/${subscriptionTrack.id}/credits/`, {
    additional_months: numberCredits,
  });

export const processCode = (payload) => _axiosNoToken.patch(`${API_PROCESSOR_URL}`, payload);
export const formatCode = (payload) => _axiosNoToken.patch(`${API_FORMATTER_URL}`, payload);

export const getPrices = (schoolId) => _axios.get(`/api/stripe/price/${schoolId}/`);

export const getPricesB2C = () => _axios.get(`/api/stripe/price/`);

export const userRequestHelp = ({ exercice_id, user, type }) =>
  _axios.post('/api/users/exercice-help/', { exercice_id, user, type });

export const getActivity = (user_id) => _axios.get(`/api/users/activity/${user_id}/`);
export const getSessionsUser = (user_id) => _axios.get(`/api/users/session/${user_id}/`);
export const getReport = (user_id) => _axios.get(`/api/users/report/${user_id}/`);

export const getRooms = (schoolId) => _axios.get(`/api/organize/school/${schoolId}/room/`);

export const createRoom = (payload) =>
  _axios.post(`/api/organize/school/${payload.school}/room/`, payload);

export const getRoom = (roomId, schoolId) =>
  _axios.get(`/api/organize/school/${schoolId}/room/${roomId}/`);

export const updateRoom = (room_id, school_id, payload) =>
  _axios.patch(`/api/organize/school/${school_id}/room/${room_id}/`, payload);

export const deleteRoom = (roomId, schoolId) =>
  _axios.delete(`/api/organize/school/${schoolId}/room/${roomId}/`);
export const getParticipants = (room_id) => _axios.get(`/api/organize/room/${room_id}/spot-check/`);

export const getSessions = (room_id) => _axios.get(`/api/organize/room/${room_id}/sessions/`);

// bulk create the sessions
export const createSessions = (room_id, sessions) =>
  _axios.post(`/api/organize/room/${room_id}/sessions/`, sessions);

export const updateSchoolStyle = (schoolId, schoolStyle) => {
  const formData = new FormData();
  Object.keys(schoolStyle).forEach((key) => {
    if (schoolStyle[key]) {
      formData.append(key, schoolStyle[key]);
    }
  });

  return _axios.patch(`/api/users/schools/${schoolId}/`, formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
};

export const getSchoolStyle = (subDomain) => {
  return _axios.get(`/api/style/school/${subDomain}/`);
};
export const getSchool = (schoolId) => _axios.get(`/api/users/schools/${schoolId}/`);

export const createTeacherDenial = (roomId, payload) =>
  _axios.post(`/api/organize/room/${roomId}/teacher-denial/`, payload);

export const completeVideo = (videoId) => _axios.post(`/api/courses/video/${videoId}/`);

export const getCoursesTeacher = () => _axios.get(`/api/courses/teacher/`);

export const getFileData = async (filepath) => {
  const dataPromise = _axios.get(filepath, { responseType: 'arraybuffer' }).then((res) => {
    // res.data.arrayBuffer();
    return res.data;
  });
  const buf = await dataPromise;
  return new Uint8Array(buf);
};


export const explainCode = (payload) => _axiosNoToken.post(`/api/courses/explain/`, payload); 


export const feedbackPrompt = (prompt_id, feedback) => {
  return _axios.patch(`/api/users/prompt-feedback/${prompt_id}/`, { feedback });
}


export const getSchoolReports = (school_id) => _axios.get(`/api/users/report/school/${school_id}/`);


export const createReport = (schoolId, payload) => _axios.post(`/api/users/report/school/${schoolId}/`, payload);

export const getLatestBuildLogs = () => _axios.get(`/api/latest-build-logs/`);

export const analyzeLogs = (logs) => _axios.get(`/api/analyze-logs/?logs=${encodeURIComponent(logs)}`);


export const generateQuiz = (payload) => _axios.post(`/api/quiz-generator/`, payload);